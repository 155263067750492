@import "~bootstrap/scss/bootstrap.scss";
@import "./fonts.scss";
@import "./helpers.scss";
@import "./form.scss";

$body-bg: white;

html,
#root {
}

body {
  margin: 0;
  font-family: new-order, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
  outline: none;
}
