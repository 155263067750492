@import url("https://p.typekit.net/p.css?s=1&k=eht2nwg&ht=tk&f=47890.47891.47892.47893.47894&a=56266181&app=typekit&e=css");

@font-face {
  font-family: "new-order";
  src: url("https://use.typekit.net/af/5689eb/00000000000000007735ee23/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/5689eb/00000000000000007735ee23/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/5689eb/00000000000000007735ee23/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: "new-order";
  src: url("https://use.typekit.net/af/01d619/00000000000000007735ee26/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/01d619/00000000000000007735ee26/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3")
      format("woff"),
    url("https://use.typekit.net/af/01d619/00000000000000007735ee26/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 600;
  font-stretch: normal;
}

@font-face {
  font-family: "new-order";
  src: url("https://use.typekit.net/af/0aebd6/00000000000000007735ee24/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/0aebd6/00000000000000007735ee24/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3")
      format("woff"),
    url("https://use.typekit.net/af/0aebd6/00000000000000007735ee24/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 500;
  font-stretch: normal;
}

@font-face {
  font-family: "new-order";
  src: url("https://use.typekit.net/af/0ce2e9/00000000000000007735ee25/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/0ce2e9/00000000000000007735ee25/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff"),
    url("https://use.typekit.net/af/0ce2e9/00000000000000007735ee25/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
}

@font-face {
  font-family: "new-order";
  src: url("https://use.typekit.net/af/9058cf/00000000000000007735ee27/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/9058cf/00000000000000007735ee27/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3")
      format("woff"),
    url("https://use.typekit.net/af/9058cf/00000000000000007735ee27/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 300;
  font-stretch: normal;
}

@font-face {
  font-family: "Neue-Haas";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/neue-haas/Neue-Haas-GroteskRoman.ttf") format("truetype"),
    url("../fonts/neue-haas/Neue-Haas-GroteskRoman.woff") format("woff"),
    url("../fonts/neue-haas/Neue-Haas-GroteskRoman.woff2") format("woff2");
}
@font-face {
  font-family: "Neue-Haas";
  font-style: normal;
  font-weight: bold;
  src: url("../fonts/neue-haas/Neue-Haas-GroteskBold.ttf") format("truetype"),
    url("../fonts/neue-haas/Neue-Haas-GroteskBold.woff") format("woff"),
    url("../fonts/neue-haas/Neue-Haas-GroteskBold.woff2") format("woff2");
}
