.input-wrapper {
  position: relative;
  .form-input {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 14px;
    border: 1px solid #bdccd4;
    border-radius: 4px;
    outline: none;
    font-weight: 400;
    font-size: 13px;
    font-family: Neue-Haas, sans-serif;
    &:focus {
      border-color: #000000;
    }
    &.error {
      border-color: #d40000;
    }
    &textarea {
      resize: vertical;
      min-height: 50px;
      max-height: 700px;
    }

    &.outline {
      background-color: transparent;
      color: #fff;
      &:focus {
        border-color: #fff;
      }
    }
    &.with-prefix {
      padding-left: 30px;
    }
  }
  .prefix {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    color: #fff;
    width: 40px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.main-button {
  padding: 17px 50px;
  background-color: #fff;
  color: #000;
  font-family: "new-order", sans-serif;
  font-weight: 700;
  border: none;
  outline: none;
  border-radius: 24px;
  transition: all ease 0.3s;
  &.medium {
    height: 48px;
    line-height: 48px;
    border-radius: 24px;
    padding: 0 60px;
  }
  &:not(:disabled) {
    &:hover,
    &:focus {
      color: #000;
      cursor: pointer;
      background-color: #ccc;
    }
  }
  &.secondary {
    background-color: #bfccd3;
    &:not(:disabled) {
      &:hover,
      &:focus {
        background-color: #868f94;
      }
    }
  }
}

@media all and (max-width: 768px) {
  .main-button {
    padding: 13px 30px;
    border-radius: 18px;
  }
}
